import { graphql, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useMemo, useState, useRef } from "react";
import Moment from "react-moment";
import Layout from "../../../components/Layout";
import Pagination from "../../../components/Pagination/Pagination";
import StandardBanner from "../../../components/StandardHero/StandardHero";

import {
  eventsBannerContainer,
  eventsBannerContent,
  eventsContentContainer,
  alleventsContainer,
  eventContent,
  paginationContainer,
} from "./index.module.scss";
const PageSize = 16;
const Events = ({
  data: {
    strapiEvents,
    strapiEvents: { subBanner, metaData },
    allStrapiEvents: { nodes: allevents },
  },
}) => {
  const bannerImage =
    strapiEvents.heroBanner.image.localFile.childImageSharp.gatsbyImageData;
  const bannerTitle = "";
  const showBreadcrumb = strapiEvents.heroBanner.showBreadcrumb;

  const scrollToRef = useRef();

  const breadcrumbs = [{ name: "EVENTS" }];

  const [currentPage, setCurrentPage] = useState(1);

  const currentEvents = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return allevents.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, allevents]);

  const handleOnPageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo({ behavior: "smooth", top: scrollToRef.current.offsetTop });
  };

  return (
    <Layout
      seo={{
        title: metaData?.pageTitle,
        description: metaData?.metadescription,
        image: bannerImage,
        article: false,
      }}
    >
      <StandardBanner
        image={bannerImage}
        title={bannerTitle}
        showBreadcrumb={showBreadcrumb}
        breadcrumbs={breadcrumbs}
      />
      <section className={eventsBannerContainer}>
        <img src={subBanner.image.localFile.publicURL} alt="logo" />
        <div className={eventsBannerContent}>
          <h3>Events</h3>
        </div>
      </section>

      <section className={eventsContentContainer} ref={scrollToRef}>
        <div className={alleventsContainer}>
          {currentEvents.map(
            ({ shortDesc, image, media_type, publish_at, slug }, index) => {
              return (
                <div
                  key={index}
                  className={eventContent}
                  onClick={() => navigate(`/events/${slug}`)}
                  onKeyDown={(event) => {
                    if (event.keycode === 13) navigate(`/events/${slug}`);
                  }}
                  tabIndex={index}
                >
                  <GatsbyImage
                    image={
                      image?.image?.localFile?.childImageSharp?.gatsbyImageData
                    }
                    alt={image?.image?.alternativeText}
                  />
                  <h4>{shortDesc}</h4>
                  <p className="ui">
                    <Moment format="D MMM YYYY">{publish_at}</Moment>
                  </p>
                  {/* Temporarily commented out.  to be added later
                  <div className={eventTagsContainer}>
                    <p className="ui">retirement</p>
                    <p className="ui">dws</p>
                  </div> */}
                </div>
              );
            }
          )}
        </div>
        <div className={paginationContainer}>
          <Pagination
            currentPage={currentPage}
            totalCount={allevents.length}
            pageSize={PageSize}
            onPageChange={(page) => handleOnPageChange(page)}
          />
        </div>
      </section>
    </Layout>
  );
};

export default Events;

export const query = graphql`
  {
    strapiEvents: strapiLatestInsights {
      heroBanner {
        title
        showBreadcrumb
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      subBanner {
        heading
        image {
          localFile {
            publicURL
          }
          alternativeText
        }
      }

      metaData {
        pageTitle
        metadescription
      }
    }
    allStrapiEvents(sort: { order: DESC, fields: publish_at }) {
      nodes {
        shortDesc
        slug
        subtitle
        published_at
        publish_at
        media_type {
          tyoe
        }
        image {
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 420
                  width: 645
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
        }
      }
    }
  }
`;
